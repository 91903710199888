import SendIcon from '@material-ui/icons/Send';

import AdminMessageList from './AdminMessageList';
import AdminMessageEdit from './AdminMessageEdit';
import AdminMessageCreate from './AdminMessageCreate';

export default {
    list: AdminMessageList,
    edit: AdminMessageEdit,
    create: AdminMessageCreate,
    icon: SendIcon,
};
