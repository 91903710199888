import * as React from 'react';
import { Button, useUnselectAll } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';

const AddToUsersBtn = ({ selectedIds }) => {
    const { values } = useFormState();
    const unselectAll = useUnselectAll();
    const form = useForm();
    const addUsersToLists = () => {
        if (values && values.users)
            form.mutators.setUsers([...values.users, ...selectedIds]);
        else form.mutators.setUsers([...selectedIds]);
        unselectAll('users');
    };

    return (
        <Button label="Ajouter à la liste" onClick={addUsersToLists}></Button>
    );
};

export default AddToUsersBtn;
