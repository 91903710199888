import React, { ComponentType } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { ChipField } from 'react-admin';
import { FieldProps } from '../types';
// import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        oui: {
            color: 'green',
            borderColor: 'green',
        },
        non: {
            color: 'red',
            borderColor: 'red',
        },
    })
);

const colored = <T extends FieldProps>(
    WrappedComponent: ComponentType<T>
): ComponentType<T> => {
    const Colored = (props: T) => {
        const classes = useStyles({});
        return props.record && props.source ? (
            props.record[props.source] === true ? (
                <Chip
                    size="small"
                    label="Oui"
                    variant="outlined"
                    className={classes.oui}
                />
            ) : (
                <Chip
                    size="small"
                    label="Non"
                    variant="outlined"
                    className={classes.non}
                />
            )
        ) : null;
    };

    Colored.displayName = `Colored(${WrappedComponent.displayName})`;

    return Colored;
};

const ColoredBooleanField = colored<typeof ChipField>(ChipField);
ColoredBooleanField.defaultProps = ChipField.defaultProps;

export default ColoredBooleanField;
