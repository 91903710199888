import * as React from 'react';
import { FC } from 'react';
import { Avatar, Link, Grid } from '@material-ui/core';
import { Customer, FieldProps } from '../../types';

interface Props extends FieldProps<Customer> {
    className?: string;
    size?: string;
}

const AvatarField: FC<Props> = ({ record, size = '25', className }) =>
    record ? (
        <Link href={`#/users/${record.id}`}>
            <Grid container direction="row" alignItems="center">
                <Avatar
                    src={`${record.avatar}?size=${size}x${size}`}
                    style={{
                        width: parseInt(size, 10),
                        height: parseInt(size, 10),
                        margin: 5,
                    }}
                    className={className}
                />
                <span style={{ color: 'black' }}>
                    {' '}
                    {record.name.first + ' ' + record.name.last}
                </span>
            </Grid>
        </Link>
    ) : null;

export default AvatarField;
