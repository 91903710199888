import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';

import List from './CollectionList';
import Edit from './CollectionEdit';
import Create from './CollectionCreate';

export default {
    list: List,
    edit: Edit,
    create: Create,
    icon: CollectionsBookmarkIcon,
};
