import React from 'react';
import { useRecordContext, ReferenceField, TextField } from 'react-admin';

interface ParentFieldProps {
    source: string;
    reference: string;
    label: string;
}

const ParentField: React.FC<ParentFieldProps> = ({ source, reference, label }) => {
    const record = useRecordContext();

    if (!record || !record[source]) {
        return <span>Aucun</span>;
    }

    return (
        <ReferenceField source={source} reference={reference} label={label}>
            <TextField source="name" />
        </ReferenceField>
    );
};

export default ParentField;
