import * as React from 'react';
import { FC } from 'react';
import { Customer, FieldProps } from '../../types';

const API_URL = process.env.REACT_APP_REST_API_URL;
interface Props extends FieldProps<Customer> {
    className?: string;
    size?: string;
}

const Thumbnailfield: FC<Props> = ({ record, size = '30', className }) =>
    record ? (
        <img
            alt=""
            src={`${API_URL}/files/${record.logo}/view?size=${size}x${size}`}
            style={{ height: parseInt(size, 16) }}
            className={className}
        />
    ) : null;

export default Thumbnailfield;
