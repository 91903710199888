import React, { useEffect, useState } from 'react';
import {
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    BooleanInput,
    minValue,
    maxValue,
    NumberInput,
    FormDataConsumer,
    useRecordContext,
    required,
} from 'react-admin';
import { Box, Typography, Collapse } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const daysOfWeek = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
];

interface OpeningTime {
    day: string;
    isOpen: boolean;
    open?: {
        hour: number;
        minute: number;
    };
    close?: {
        hour: number;
        minute: number;
    };
}

interface OpeningTimeInputProps {
    source: string;
    label: string;
    validate?: any;
    translate: (key: string) => string;
}

const OpeningTimeInput: React.FC<OpeningTimeInputProps> = ({
    source,
    label,
    validate,
    translate,
}) => {
    const record = useRecordContext();
    const [openingTimes, setOpeningTimes] = useState<OpeningTime[]>([]);
    const [openWorkingTime, setOpenWorkingTime] = useState(true);
    const [selectedDays, setSelectedDays] = useState<string[]>([]);

    useEffect(() => {
        if (record && record[source] && record[source].length > 0) {
            const initialTimes = record[source].map((item: OpeningTime) => ({
                ...item,
                isOpen: item.isOpen !== undefined ? item.isOpen : false,
            }));
            setOpeningTimes(initialTimes);
            setSelectedDays(initialTimes.map((time: OpeningTime) => time.day));
        }
    }, [record, source]);

    const handleDayChange = (index: number, newDay: string) => {
        setOpeningTimes(prevTimes => {
            if (index >= 0 && index < prevTimes.length) {
                const updatedTimes = [...prevTimes];
                const oldDay = updatedTimes[index].day;
                updatedTimes[index] = { ...updatedTimes[index], day: newDay };

                setSelectedDays(prevSelectedDays => {
                    const updatedSelectedDays = prevSelectedDays.filter(
                        day => day !== oldDay
                    );
                    updatedSelectedDays.push(newDay);
                    return updatedSelectedDays;
                });

                return updatedTimes;
            }
            return prevTimes;
        });
    };

    const getAvailableDays = (currentDay: string) => {
        return daysOfWeek.filter(
            day => !selectedDays.includes(day) || day === currentDay
        );
    };

    return (
        <Box>
            <Box display="flex">
                <Typography variant="h6" gutterBottom>
                    {translate('resources.activities.fields.workingTime')}
                </Typography>
                <button
                    type="button"
                    style={{
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        border: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: `rotate(${openWorkingTime ? 180 : 0}deg)`,
                        transition: 'transform 0.5s ease-in-out',
                    }}
                    onClick={() => setOpenWorkingTime(!openWorkingTime)}
                >
                    <ArrowDropDownIcon />
                </button>
            </Box>
            <Collapse in={openWorkingTime}>
                <ArrayInput source={source} label={label} validate={validate}>
                    <SimpleFormIterator disableRemove>
                        <FormDataConsumer>
                            {({ getSource, scopedFormData, index }) => {
                                const currentDay = scopedFormData?.day;
                                const availableDays =
                                    getAvailableDays(currentDay);

                                return (
                                    <Box mb={2}>
                                        <SelectInput
                                            source={getSource('day')}
                                            choices={availableDays.map(day => ({
                                                id: day,
                                                name: day,
                                            }))}
                                            label={translate(
                                                'resources.activities.fields.day'
                                            )}
                                            onChange={e =>
                                                handleDayChange(
                                                    index,
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <BooleanInput
                                            source={getSource('isOpen')}
                                            label={translate(
                                                'resources.activities.fields.isOpen'
                                            )}
                                        />
                                        {scopedFormData?.isOpen && (
                                            <>
                                                <Typography>
                                                    {translate(
                                                        'resources.activities.fields.from'
                                                    )}
                                                </Typography>
                                                <Box
                                                    display={{
                                                        xs: 'block',
                                                        sm: 'flex',
                                                    }}
                                                >
                                                    <Box
                                                        flex={1}
                                                        mr={{
                                                            xs: 0,
                                                            sm: '0.5em',
                                                        }}
                                                    >
                                                        <NumberInput
                                                            source={getSource(
                                                                'open.hour'
                                                            )}
                                                            label={translate(
                                                                'resources.activities.fields.hour'
                                                            )}
                                                            min={0}
                                                            max={23}
                                                            validate={[
                                                                required(),
                                                                minValue(
                                                                    0,
                                                                    'Les heures doivent être entre 0 et 23'
                                                                ),
                                                                maxValue(
                                                                    23,
                                                                    'Les heures doivent être entre 0 et 23'
                                                                ),
                                                            ]}
                                                        />
                                                    </Box>
                                                    <Box
                                                        flex={1}
                                                        mr={{
                                                            xs: 0,
                                                            sm: '0.5em',
                                                        }}
                                                    >
                                                        <NumberInput
                                                            source={getSource(
                                                                'open.minute'
                                                            )}
                                                            label={translate(
                                                                'resources.activities.fields.minute'
                                                            )}
                                                            min={0}
                                                            max={59}
                                                            validate={[
                                                                required(),
                                                                minValue(
                                                                    0,
                                                                    'Les minutes doivent être entre 0 et 59'
                                                                ),
                                                                maxValue(
                                                                    59,
                                                                    'Les minutes doivent être entre 0 et 59'
                                                                ),
                                                            ]}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Typography>
                                                    {translate(
                                                        'resources.activities.fields.to'
                                                    )}
                                                </Typography>
                                                <Box
                                                    display={{
                                                        xs: 'block',
                                                        sm: 'flex',
                                                    }}
                                                >
                                                    <Box
                                                        flex={1}
                                                        mr={{
                                                            xs: 0,
                                                            sm: '0.5em',
                                                        }}
                                                    >
                                                        <NumberInput
                                                            source={getSource(
                                                                'close.hour'
                                                            )}
                                                            label={translate(
                                                                'resources.activities.fields.hour'
                                                            )}
                                                            min={0}
                                                            max={23}
                                                            validate={[
                                                                required(),
                                                                minValue(
                                                                    0,
                                                                    'Les heures doivent être entre 0 et 23'
                                                                ),
                                                                maxValue(
                                                                    23,
                                                                    'Les heures doivent être entre 0 et 23'
                                                                ),
                                                            ]}
                                                        />
                                                    </Box>
                                                    <Box
                                                        flex={1}
                                                        mr={{
                                                            xs: 0,
                                                            sm: '0.5em',
                                                        }}
                                                    >
                                                        <NumberInput
                                                            source={getSource(
                                                                'close.minute'
                                                            )}
                                                            label={translate(
                                                                'resources.activities.fields.minute'
                                                            )}
                                                            min={0}
                                                            max={59}
                                                            validate={[
                                                                required(),
                                                                minValue(
                                                                    0,
                                                                    'Les minutes doivent être entre 0 et 59'
                                                                ),
                                                                maxValue(
                                                                    59,
                                                                    'Les minutes doivent être entre 0 et 59'
                                                                ),
                                                            ]}
                                                        />
                                                    </Box>
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </Collapse>
        </Box>
    );
};

export default OpeningTimeInput;