import React, { useState, useEffect, useCallback, FC } from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import NbSubscriptions from './NbSubscriptions';

interface State {
    nbUsers?: number;
    nbActivties?: number;
}

const useStyles = makeStyles({
    card: {
        maxHeight: 1000,
        marginTop: 10,
        boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.15)',
        borderRadius: 5,
        height: 200,
    },
});

const styles = {
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
    sectionTitle: { fontSize: 14 },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard: FC = () => {
    const [state, setState] = useState<State>({});
    const version = useVersion();
    const dataProvider = useDataProvider();

    const classes = useStyles();

    const fetchUsers = useCallback(async () => {
        try {
            let query = {
                filter: {},
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 1 },
                select: 'name',
            };

            const { total: nbUsers } = await dataProvider.getList(
                'users',
                query
            );

            setState(state => ({
                ...state,
                nbUsers: nbUsers || 0,
            }));
        } catch (error) {
            console.error('Error fetching users:', error);
            setState(state => ({
                ...state,
                nbUsers: 0,
            }));
        }
    }, [dataProvider]);

    const fetchActivites = useCallback(async () => {
        try {
            let query = {
                filter: {},
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 1 },
                select: 'name',
            };

            const result = await dataProvider.getList('activities', query);
            const nbActivties = result && result.total ? result.total : 0;

            setState(state => ({
                ...state,
                nbActivties,
            }));
        } catch (error) {
            console.error('Error fetching activities:', error);
            setState(state => ({
                ...state,
                nbActivties: 0,
            }));
        }
    }, [dataProvider]);

    useEffect(() => {
        fetchUsers();
        fetchActivites();
    }, [version, fetchUsers, fetchActivites]);

    return (
        <>
            <Card className={classes.card}>
                <CardContent>
                    <div style={styles.flex}>
                        <NbSubscriptions
                            label="pos.dashboard.nbUsers"
                            value={state.nbUsers}
                            to="/users"
                        />
                        <Spacer />
                        <NbSubscriptions
                            label="pos.dashboard.nbActivties"
                            to="/activities"
                            value={state.nbActivties}
                        />
                    </div>
                </CardContent>
            </Card>
            <VerticalSpacer />
        </>
    );
};

export default Dashboard;
