import * as React from 'react';
import { useState, useEffect } from 'react';
import { Admin, Resource, resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';

import users from './users';
import admins from './admins';
import collections from './collections';
import categories from './categories';
import slides from './slides';
import tags from './tags';
import cities from './cities';
import activities from './activities';
import admin_messages from './admin_messages';
import dataProviderFactory from './dataProvider';
import { Dashboard } from './dashboard';
import CustomeRoutes from './layout/CustomeRoutes';

const messages = {
    fr: frenchMessages,
    en: englishMessages,
};

const i18nProvider = polyglotI18nProvider(
    locale => (messages[locale] ? messages[locale] : messages.en),
    resolveBrowserLocale()
);

const App = () => {
    const [dataProvider, setDataProvider] = useState(null);

    useEffect(() => {
        let restoreFetch;

        const fetchDataProvider = async () => {
            const dataProviderInstance = await dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER || ''
            );

            restoreFetch = dataProviderInstance;

            setDataProvider(
                // GOTCHA: dataProviderInstance can be a function
                () => dataProviderInstance
            );
        };

        fetchDataProvider();

        return restoreFetch;
    }, []);

    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Chargement...</div>
            </div>
        );
    }

    return (
        <Admin
            title=""
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={CustomeRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            <Resource name="slides" {...slides} />
            <Resource name="activities" {...activities} />
            <Resource name="admin_messages" {...admin_messages} />
            <Resource name="tags" {...tags} />
            <Resource name="categories" {...categories} />
            <Resource name="cities" {...cities} />
            <Resource name="collections" {...collections} />
            <Resource name="users" {...users} />
            <Resource name="admins" {...admins} />
        </Admin>
    );
};

export default App;
