import React, { useEffect } from 'react';
import {

    ReferenceInput,
    SelectInput,
    FormDataConsumer,
} from 'react-admin';
import { Box } from '@material-ui/core';
import ClearableSelectInput from './ClearableSelectInput';
import { useInput } from 'react-admin';
import { useForm } from 'react-final-form';


const DynamicLabelReferenceInput = props => {
    const {
        input: { value },
    } = useInput(props);

    const label = value
        ? 'resources.activities.fields.subCategory'
        : 'Sous catégorie : Aucune';

    return <ReferenceInput {...props} label={label} />;
};
const CategorySubcategoryInput = ({ validate }) => {
    const { change } = useForm();

    return (
        <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <ReferenceInput
                    source="category"
                    reference="categories"
                    label="resources.activities.fields.category"
                    filter={{
                        parent: null,
                    }}
                    validate={validate}
                    onChange={() => {
                        // Clear subcategory when category changes
                        change('subCategory', null);
                    }}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <FormDataConsumer>
                    {({ formData }) => (
                        <DynamicLabelReferenceInput
                            source="subCategory"
                            reference="categories"
                            filter={{
                                parent: formData.category,
                            }}
                            disabled={!formData.category}
                        >
                            <ClearableSelectInput
                                optionText="name"
                                emptyValue={null}
                            />
                        </DynamicLabelReferenceInput>
                    )}
                </FormDataConsumer>
            </Box>
        </Box>
    );
};

export default CategorySubcategoryInput;