import CategoryIcon from '@material-ui/icons/Category';

import List from './CategoriesList';
import Edit from './CategoryEdit';
import Create from './CategoryCreate';

export default {
    list: List,
    edit: Edit,
    create: Create,
    icon: CategoryIcon,
};
