import ActivityIcon from '@material-ui/icons/Star';

import ActivityList from './ActivityList';
import ActivityCreate from './ActivityCreate';
import ActivityEdit from './ActivityEdit';

export default {
    list: ActivityList,
    create: ActivityCreate,
    edit: ActivityEdit,
    icon: ActivityIcon,
};
