import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    Filter,
    List,
    SearchInput,
    ReferenceField,
} from 'react-admin';
import ColoredBooleanField from '../fields/common/ColoredBooleanField';

import { ReferenceInput, AutocompleteInput } from 'react-admin';
import { BooleanInput } from 'react-admin';
import Thumbnailfield from './fields/ThumbnailField';
import ParentField from './fields/ParentField';

const ActivtyFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <BooleanInput
            source="isEnabled"
            label="resources.activities.fields.isEnabled"
        />
        <BooleanInput
            source="hasComplaints"
            label="resources.activities.fields.hasComplaints"
        />
          <BooleanInput
            source="hasReviews"
            label="resources.activities.fields.hasReviews"
        />
        <ReferenceInput
            label="resources.activities.fields.city"
            source="city"
            reference="cities"
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            label="resources.activities.fields.category"
            source="category"
            reference="categories"
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            label="resources.activities.fields.activityCollection"
            source="activityCollection"
            reference="collections"
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const ActivitiesList = (props: any) => {
    return (
        <List
            {...props}
            filters={<ActivtyFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="edit">
                <TextField
                    source="uid"
                    label="resources.activities.fields.uid"
                />
                <Thumbnailfield
                    source="logo"
                    label="resources.activities.fields.logoList"
                />
                <TextField
                    source="title"
                    label="resources.activities.fields.title"
                />
                <ReferenceField
                    label="resources.activities.fields.activityCollection"
                    source="activityCollection"
                    reference="collections"
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                    label="resources.activities.fields.category"
                    source="category"
                    reference="categories"
                >
                    <TextField source="name" />
                </ReferenceField>
                <ParentField
                    label="resources.activities.fields.subCategory"
                    source="subCategory"
                    reference="categories"
                />
                <TextField
                    source="cityName"
                    label="resources.activities.fields.city"
                />
                <TextField
                    source="clickContact"
                    label="resources.activities.fields.clickContact"
                />
                <TextField
                    source="phone"
                    label="resources.activities.fields.phone"
                />
                <ColoredBooleanField
                    source="isEnabled"
                    label="resources.activities.fields.isEnabled"
                />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.activities.fields.created_at"
                />
            </Datagrid>
        </List>
    );
};

export default ActivitiesList;
