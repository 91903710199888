import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import * as React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import './index.css';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN || '',
    debug: process.env.REACT_APP_STAGE === 'development',
    environment: process.env.REACT_APP_STAGE,
});

ReactDOM.render(<App />, document.getElementById('root'));
