import * as React from 'react';
import { Edit, Toolbar, TabbedForm, FormTab } from 'react-admin';
import EditActivity from './parts/EditActivity';
import RapportActivity from './parts/RapportActivity';
import AvisActivity from './parts/AvisActivity';

const ProductToolbar = props => <Toolbar {...props} />;
const ActivityEdit = (props: any) => {
    return (
        <Edit title="Activité" undoable={false} component="div" {...props}>
            <TabbedForm toolbar={<ProductToolbar />}>
                <FormTab label="resources.activities.fields.details">
                    <EditActivity />
                </FormTab>
                <FormTab label="Raport" path="raport">
                    <RapportActivity id={props.id} />
                </FormTab>
                <FormTab label="Avis" path="avis">
                    <AvisActivity id={props.id} />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default ActivityEdit;
