import * as React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

const type = ['all', 'users'];

const toChoices = (items, translate) =>
    items.map(item => ({
        id: item,
        name: translate(`resources.admin_messages.targets.${item}`),
    }));
const TypeInput = props => {
    const translate = useTranslate();

    return (
        <SelectInput
            choices={toChoices(type, translate)}
            {...props}
            emptyText="Tout"
        />
    );
};

export default TypeInput;
