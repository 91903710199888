import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    Filter,
    List,
    SearchInput,
} from 'react-admin';
import ColoredBooleanField from '../fields/common/ColoredBooleanField';

const PcFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);
const SlideList = (props: any) => {
    return (
        <List
            {...props}
            filters={<PcFilter />}
            sort={{ field: 'title', order: 'ASC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="edit">
                <TextField source="name" label="resources.tags.fields.name" />
                <ColoredBooleanField
                    source="isEnabled"
                    label="resources.tags.fields.is_enabled"
                />
                <DateField
                    source="created_at"
                    type="date"
                    showTime
                    label="resources.tags.fields.created_at"
                />
        
            </Datagrid>
        </List>
    );
};

export default SlideList;
