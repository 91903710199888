import * as React from 'react';
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { Box } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import UsersSelector from '../btns/UsersSelector';

const AdminMessageTargetUsersInput = props => {
    const { values } = useFormState();
    const isMounted = React.useRef(true); // Track whether component is mounted

    React.useEffect(() => {
        // Cleanup function to set isMounted to false when component unmounts
        return () => {
            isMounted.current = false;
        };
    }, []);

    if (!values || !values.target || values.target !== 'users') return null;

    // Conditionally rendering based on the form's values
    return (
        <Box display="block" alignItems="center"> {/* Corrected display value */}
            <UsersSelector />
            <ReferenceArrayInput {...props}>
            <AutocompleteArrayInput optionText={choice => choice && choice.name ? choice.name.first + ' ' + choice.name.last : ''} />
            </ReferenceArrayInput>
        </Box>
    );
};

export default AdminMessageTargetUsersInput;
