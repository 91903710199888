import tagsIcon from '@material-ui/icons/Style';

import List from './TagList';
import Edit from './TagEdit';
import Create from './TagCreate';

export default {
    list: List,
    edit: Edit,
    create: Create,
    icon: tagsIcon,
};
