import React, { useState } from 'react';
import {
    Button,
    SaveButton,
    useCreate,
    useNotify,
    useRefresh,
    FormWithRedirect,
} from 'react-admin';
import SendIcon from '@material-ui/icons/Send';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function SendAdminMessageButton({ onChange, adminMessageId, resend }) {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate(
        `admin_messages/${adminMessageId}/send`
    );
    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        create(
            { payload: { data: {} } },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);
                    refresh();

                    onChange();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                },
            }
        );
    };
    const label = resend
        ? 'resources.admin_messages.actions.resend'
        : 'resources.admin_messages.actions.send';
    return (
        <>
            <Button onClick={handleClick} label={label}>
                <SendIcon />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Create post"
            >
                <DialogTitle>Diffuser le Message</DialogTitle>

                <FormWithRedirect
                    resource="qrcodes"
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving,
                    }) => (
                        <>
                            <DialogContent>
                                <p>
                                    êtes vous sure de vouloir envoyer le
                                    message?
                                </p>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    label="Envoyer"
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

export default SendAdminMessageButton;
