import React, { useEffect, useState, useCallback } from 'react';
import {
    ReferenceInput,
    SelectInput,
    // required,
    useDataProvider,
    useRecordContext,
    useNotify,
} from 'react-admin';

const ClearableSelectInput = props => {
    const choicesWithEmpty = [
        { id: null, name: 'Aucune' }, // Adding an empty option
        ...props.choices,
    ];

    return <SelectInput {...props} choices={choicesWithEmpty} resettable />;
};
interface Collection {
    id: string;
    name: string;
}

interface CollectionRefProps {
    setHasMessage: (hasMessage: boolean) => void;
}

const CollectionRef: React.FC<CollectionRefProps> = ({ setHasMessage }) => {
    const dataProvider = useDataProvider();
    const [collections, setCollections] = useState<Collection[]>([]);
    const record = useRecordContext();
    const notify = useNotify();

    const fetchList = useCallback(async () => {
        try {
            const result = await dataProvider.getList('collections', {
                pagination: { page: 1, perPage: 200 },
                sort: { field: 'name', order: 'ASC' },
                filter: { q: 'Promotions' },
            });
            setCollections(result.data as Collection[]);
        } catch (error) {
            console.error('Error fetching collections:', error);
            notify('Error fetching collections', { type: 'error' });
        }
    }, [dataProvider, notify]);

    useEffect(() => {
        fetchList();
    }, [fetchList]);

    useEffect(() => {
        if (collections.length > 0 && record && record.activityCollection) {
            const currentCollection = collections.find(
                collection => collection.id === record.activityCollection.id
            );
            setHasMessage(currentCollection?.name !== 'Promotions');
        }
    }, [collections, record, setHasMessage]);

    const handleCollectionChange = useCallback(
        async (event: React.ChangeEvent<HTMLSelectElement>) => {
            const selectedCollectionId = event.target.value;

            const selectedCollection = collections.find(
                collection => collection.id.toString() === selectedCollectionId
            );

            if (selectedCollection) {
                setHasMessage(selectedCollection.name !== 'Promotions');
            } else {
                setHasMessage(true);
            }
        },
        [collections, setHasMessage]
    );

    // const validate = [required()];

    return (
        <ReferenceInput
            source="activityCollection._id"
            reference="collections"
            label="resources.activities.fields.activityCollection"
            // validate={validate}
            onChange={handleCollectionChange}
        >
            <ClearableSelectInput optionText="name" emptyValue={null} fullWidth />
        </ReferenceInput>
    );
};

export default CollectionRef;
