import * as React from 'react';
import {
    TextInput,
    NumberInput,
    BooleanInput,
    required,
    ArrayInput,
    maxValue,
    minValue,
    useInput,
    maxLength,
    SimpleFormIterator,
    ReferenceInput,
    useTranslate,
    SelectInput,
    FormDataConsumer,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Box, Typography, Button } from '@material-ui/core';
import ClearableSelectInput from '../fields/ClearableSelectInput';
import MyImageInput from '../../inputs/MyImageInputMulti';
import MyImageInput2 from '../fields/MyImageInput2';
import CollectionRef from '../fields/CollectionRef';
import OpeningTimeInput from '../fields/OpeningTimeInput';
import CategorySubcategoryInput from '../fields/CategorySubcategoryInput';

const DynamicLabelReferenceInput = props => {
    const {
        input: { value },
    } = useInput(props);

    const label = value
        ? 'resources.activities.fields.subCategory'
        : 'Sous catégorie : Aucune';

    return <ReferenceInput {...props} label={label} />;
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

const ProductForm = (props: any) => {
    const translate = useTranslate();
    const classes = useStyles({});
    const [hasMessage, setHasMessage] = React.useState(true);
    // Initialize state from props if available (e.g., when editing)
    const [latitude, setLatitude] = React.useState(
        props.record?.location?.coordinates[1] || ''
    );
    const [longitude, setLongitude] = React.useState(
        props.record?.location?.coordinates[0] || ''
    );

    // Correctly handle the change events from NumberInput
    const handleLatitudeChange = event => {
        setLatitude(event.target.value);
    };

    const handleLongitudeChange = event => {
        setLongitude(event.target.value);
    };

    // URL for Google Maps
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    const isMapButtonDisabled = !latitude || !longitude;

    const validate = [required()];
    const validatePositionLng = [
        required(),
        maxValue(180, 'Longitude doit être comprise entre -180 et 180'),
        minValue(-180, 'Longitude doit être comprise entre -180 et 180'),
    ];
    const validatePositionLat = [
        required(),
        maxValue(90, 'Latitude doit être comprise entre -90 et 90'),
        minValue(-90, 'Latitude doit être comprise entre -90 et 90'),
    ];
    return (
        <Card>
            <CardContent>
                <Box display={{ md: 'block', lg: 'flex' }}>
                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                        <Typography variant="h6" gutterBottom>
                            Mettre à jour l'activité
                        </Typography>
                        <Box display={{ xs: 'block', sm: 'flex' }}>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                    source="title"
                                    label="Le nom de l'activité"
                                    validate={validate}
                                    fullWidth
                                />
                            </Box>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                    source="uid"
                                    label="L'identifiant de l'activité"
                                    disabled
                                    fullWidth
                                />
                            </Box>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                    source="clickContact"
                                    label="Cliquez pour contacter"
                                    disabled
                                    fullWidth
                                />
                            </Box>
                        </Box>

                        <Typography variant="h6" gutterBottom>
                            {translate('resources.activities.fields.category')}
                        </Typography>
                              <CategorySubcategoryInput validate={required()} />

                        <CollectionRef setHasMessage={setHasMessage} />

                        <TextInput
                            source="aboutThisAction"
                            label="à propos de cette action"
                            fullWidth
                            multiline
                        />
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.users.fieldGroups.address')}
                            <Typography variant="caption">
                                {/* <Button
                                    component="a"
                                    href="https://geojson.io/#map=8.15/33.969/-6.585"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ marginLeft: '1em' }}
                                >
                                    Obtenir les coordonnées
                                </Button> */}

                                {isMapButtonDisabled ? (
                                    <Button
                                        component="a"
                                        href={mapUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            marginLeft: '1em',
                                        }}
                                        disabled={true}
                                    >
                                        Ajouter longitude et latitude pour voir
                                        sur la carte
                                    </Button>
                                ) : (
                                    <Button
                                        component="a"
                                        href={mapUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            marginLeft: '1em',
                                        }}
                                        disabled={isMapButtonDisabled}
                                    >
                                        Voir sur Google Map
                                    </Button>
                                )}
                            </Typography>
                        </Typography>
                        <Box display={{ xs: 'block', sm: 'flex' }}>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <NumberInput
                                    source="location.coordinates[1]"
                                    label="resources.activities.fields.latitude"
                                    validate={validatePositionLat}
                                    className={classes.inlineBlock}
                                    onChange={handleLatitudeChange}
                                />
                            </Box>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                <NumberInput
                                    source="location.coordinates[0]"
                                    label="resources.activities.fields.longitude"
                                    validate={validatePositionLng}
                                    className={classes.inlineBlock}
                                    onChange={handleLongitudeChange}
                                />
                            </Box>
                        </Box>

                        <Box display={{ xs: 'block', sm: 'flex' }}>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                    source="address"
                                    label="resources.activities.fields.address"
                                />
                            </Box>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                <ReferenceInput
                                    source="city"
                                    reference="cities"
                                    filter={{ isEnabled: true }}
                                    label="resources.activities.fields.city"
                                    validate={validate}
                                >
                                    <SelectInput optionText="name" />
                                </ReferenceInput>
                            </Box>
                        </Box>

                        <Box display={{ xs: 'block', sm: 'flex' }}>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                    source="siteWeb"
                                    label="resources.activities.fields.website"
                                />
                            </Box>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                    source="reservationLink"
                                    label="resources.activities.fields.reservationLink"
                                    // validate={validate}
                                />
                            </Box>
                        </Box>
                        <Box display={{ xs: 'block', sm: 'flex' }}>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                    source="messagePromotion"
                                    label="Message de promotion"
                                    disabled={hasMessage}
                                    validate={[
                                        maxLength(
                                            16,
                                            'Le message doit faire moins de 16 caractères'
                                        ),
                                    ]}
                                />
                            </Box>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                    source="phone"
                                    label="Téléphone"
                                    // validate={validate}
                                />
                            </Box>
                        </Box>
                        <Box mt="1em" />

                        <Box display={{ xs: 'block', sm: 'flex' }}>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <OpeningTimeInput
                                    source="openingTime"
                                    label="resources.activities.fields.openingTime"
                                    validate={validate}
                                    translate={translate}
                                />
                            </Box>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.activities.fields.tags'
                                    )}
                                </Typography>
                                <ArrayInput source="tags" label="Tags">
                                    <SimpleFormIterator>
                                        <ReferenceInput
                                            source="_id"
                                            reference="tags"
                                            label="resources.activities.fields.tags"
                                        >
                                            <SelectInput
                                                optionText="name"
                                                fullWidth
                                            />
                                        </ReferenceInput>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>
                        <Box mt="1em" />
                        <Box mt="1em" />
                        <Box mt="1em" />
                        <Box display={{ xs: 'block', sm: 'flex' }}>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.activities.fields.logo'
                                    )}
                                </Typography>
                                <MyImageInput2
                                    source="logo"
                                    label="resources.activities.fields.logo"
                                    // validate={validate}
                                />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.activities.fields.images'
                                    )}
                                </Typography>
                                <MyImageInput
                                    source="images"
                                    label="resources.activities.fields.images"
                                    validate={validate}
                                />
                            </Box>
                        </Box>
                        <BooleanInput
                            source="isEnabled"
                            label="resources.activities.fields.isEnabled"
                        />
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ProductForm;
