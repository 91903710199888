import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    TextField,
    maxValue,
    Toolbar,
    NumberInput,
    minValue,
    FormWithRedirect,
    SaveButton,
    required,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Pc, FieldProps } from '../types';
import { BooleanInput } from 'react-admin';

const SlideEdit = (props: any) => {
    return (
        <Edit
            title={<PcTitle />}
            // aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <SlideForm />
        </Edit>
    );
};

const ToolbarNoDelete = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);
const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});
const PcTitle: FC<FieldProps<Pc>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

const SlideForm = (props: any) => {
    const validateName = [required(), minLength(1), maxLength(70)];
    const classes = useStyles();
    const validatePositionLng = [
        required(),
        maxValue(180, 'La position doit être comprise entre -180 et 180'),
        minValue(-180, 'La position doit être comprise entre -180 et 180'),
    ];
    const validatePositionLat = [
        required(),
        maxValue(90, 'La position doit être comprise entre -90 et 90'),
        minValue(-90, 'La position doit être comprise entre -90 et 90'),
    ];
    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <TextInput
                                        source="name"
                                        resource="cities"
                                        label="resources.cities.fields.name"
                                        validate={validateName}
                                        fullWidth
                                    />
                                    <TextInput
                                        source="description"
                                        label="resources.cities.fields.description"
                                        fullWidth
                                        multiline
                                    />

                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                source="location.coordinates[1]"
                                                label="resources.activities.fields.latitude"
                                                validate={validatePositionLat}
                                                className={classes.inlineBlock}
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                source="location.coordinates[0]"
                                                label="resources.activities.fields.longitude"
                                                validate={validatePositionLng}
                                                className={classes.inlineBlock}
                                            />
                                        </Box>
                                    </Box>
                                    <BooleanInput
                                        source="isEnabled"
                                        label="resources.cities.fields.is_enabled"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <ToolbarNoDelete
                            {...formProps}
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="cities"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default SlideEdit;
