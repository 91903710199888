import * as React from 'react';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { DateField, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { Typography, Card, CardContent, Box } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/core/styles';

import { Record } from 'ra-core';
import SendAdminMessageButton from './btns/sendAdminMessageButton';

const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

interface AsideProps {
    record?: Record;
    basePath?: string;
}

const Aside: FC<AsideProps> = ({ record, basePath }) => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            {record && <EventList record={record} basePath={basePath} />}
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
};

interface EventListProps {
    record?: Record;
    basePath?: string;
}
const SendAdminMessageForm = ({ record }) => {
    const history = useHistory();
    const { id: admin_message_id, isSent } = record;
    const handleChange = useCallback(() => history.push('/admin_messages'), [
        history,
    ]);

    return (
        <>
            <SendAdminMessageButton
                onChange={handleChange}
                adminMessageId={admin_message_id}
                resend={isSent}
            />
        </>
    );
};
const EventList: FC<EventListProps> = ({ record, basePath }) => {
    const translate = useTranslate();
    return (
        <>
            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.admins.fieldGroups.history')}
                        </Typography>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <AccessTimeIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.admins.fields.created_at'
                                            )}
                                        </Typography>
                                        <DateField
                                            record={record}
                                            source="created_at"
                                            locales="fr-FR"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <SendIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.admin_messages.fields.last_sent_at'
                                            )}
                                        </Typography>
                                        <DateField
                                            record={record}
                                            source="last_sent_at"
                                            locales="fr-FR"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box marginTop={10}>
                            <SendAdminMessageForm record={record} />
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default Aside;
