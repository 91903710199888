import React from 'react';
import { SelectInput } from 'react-admin';

const ClearableSelectInput = props => {

    const choicesWithEmpty = [
        { id: null, name: 'Aucun' }, // Adding an empty option
        ...props.choices
    ];

    return (
        <SelectInput {...props} choices={choicesWithEmpty} resettable/>
    );
};

export default ClearableSelectInput;