import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    Filter,
    List,
    BooleanInput,
} from 'react-admin';
import AdminMesageTargetField from './fields/AdminMesageTargetField';
import ColoredBooleanField from '../users/ColoredBooleanField';
import TypeInput from './fields/AdminMesageTargetFilter';
const AdminMessageFilter = (props: any) => (
    <Filter {...props}>
        <BooleanInput
            source="isSent"
            label="resources.admin_messages.fields.is_published"
        />
        <TypeInput
            source="target"
            label="resources.admin_messages.fields.type"
        />
    </Filter>
);
const AdminMessageList = (props: any) => {
    return (
        <List
            {...props}
            filters={<AdminMessageFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="edit">
                <AdminMesageTargetField
                    source="target"
                    label="resources.admin_messages.fields.type"
                />
                <TextField
                    source="message"
                    label="resources.admin_messages.fields.message"
                />
                <ColoredBooleanField
                    source="isSent"
                    label="resources.admin_messages.fields.is_sent"
                />
                <DateField
                    source="lastSentAt"
                    type="date"
                    label="resources.admin_messages.fields.last_sent_at"
                />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.admin_messages.fields.created_at"
                />
            </Datagrid>
        </List>
    );
};

export default AdminMessageList;
