import React, { Fragment } from 'react';
import {
    List,
    Datagrid,
    useTranslate,
    Filter,
    SearchInput,
    TextField,
    Button,
} from 'react-admin';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import AddToUsersBtn from './AddToUsersBtn';
import FullNameField from '../../users/FullNameField';
const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#FFF',
        border: '2px solid #000',
        padding: '20px',
    },
});

// Filters
const UserFilter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
        </Filter>
    );
};

const BulkActions = props => (
    <Fragment>
        <AddToUsersBtn label="" {...props} />
    </Fragment>
);

const UsersSelector = props => {
    const { values } = useFormState();
    const translate = useTranslate();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const classes = useStyles();
    const isSelectable = record => {
        return (
            !values.users || values.users.find(id => id === record.id) == null
        );
    };

    return (
        <>
            <Button label="Choisir des  Clients" onClick={handleOpen}></Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <List
                            filters={<UserFilter />}
                            bulkActionButtons={<BulkActions />}
                            basePath="users"
                            resource="users"
                            hasCreate={false}
                            hasShow={false}
                            hasEdit={false}
                            hasList={false}
                            exporter={false}
                            title={translate('resources.users.name')}
                            sort={{ field: 'name', order: 'ASC' }}
                            perPage={10}
                        >
                            <Datagrid
                                optimized
                                rowClick={null}
                                isRowSelectable={isSelectable}
                            >
                                <FullNameField
                                    source="name"
                                    label="resources.users.fields.name.full"
                                />
                                <TextField
                                    source="email"
                                    label="resources.users.fields.email"
                                />
                                  <TextField
                                    source="phone"
                                    label="resources.users.fields.phone"
                                />
                                       <TextField
                                    source="uid"
                                    label="resources.users.fields.uid"
                                />
                            </Datagrid>
                        </List>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default UsersSelector;
