import * as React from 'react';
import {
    Loading,
    useDataProvider,
    useTranslate,
    DateField,
    TextField,
    useNotify,
} from 'react-admin';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    withStyles,
    createStyles,
    Theme,
} from '@material-ui/core';
import { Rating as MuiRating } from '@material-ui/lab';
import AvatarField from '../fields/UserField';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import DeleteIcon from '@material-ui/icons/Delete';
import { Customer } from '../../types';

interface RatingData {
    _id: string;
    user: Customer;
    rate: number;
    comment: string;
    created_at: string;
}

interface RatingProps {
    id: string;
    classes: {
        starLow: string;
        starMedium: string;
        starHigh: string;
    };
}

const styles = (theme: Theme) => createStyles({
    starLow: {
        '& .MuiRating-iconFilled': {
            color: '#f44336',
        },
        '& .MuiRating-iconHover': {
            color: '#f44336',
        },
        '& .MuiSvgIcon-root': {
            color: '#f44336',
        },
    },
    starMedium: {
        '& .MuiRating-iconFilled': {
            color: '#ff9800',
        },
        '& .MuiRating-iconHover': {
            color: '#ff9800',
        },
        '& .MuiSvgIcon-root': {
            color: '#ff9800',
        },
    },
    starHigh: {
        '& .MuiRating-iconFilled': {
            color: '#4caf50',
        },
        '& .MuiRating-iconHover': {
            color: '#4caf50',
        },
        '& .MuiSvgIcon-root': {
            color: '#4caf50',
        },
    },
});

const Rating: React.FC<RatingProps> = ({ id, classes }) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [ratings, setRatings] = React.useState<RatingData[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [ratingToDelete, setRatingToDelete] = React.useState<string | null>(null);

    const fetchRatings = React.useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await dataProvider.getMany(`activities/${id}/rating`, {
                sort: { field: 'created_at', order: 'ASC' },
            }) as { data: RatingData[] };
            setRatings(data);
        } catch (error) {
            console.error('Error fetching ratings:', error);
            notify('Error fetching ratings', 'error');
        } finally {
            setLoading(false);
        }
    }, [dataProvider, id, notify]);

    React.useEffect(() => {
        fetchRatings();
    }, [fetchRatings]);

    const handleDeleteClick = (ratingId: string) => {
        setRatingToDelete(ratingId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (ratingToDelete) {
            try {
                await dataProvider.delete(`activities/${id}/rating`, { id: ratingToDelete });
                notify('Review deleted successfully', 'info');
                setRatings(ratings.filter(rating => rating._id !== ratingToDelete));
            } catch (error) {
                console.error('Error deleting review:', error);
                notify('Error deleting review', 'error');
            }
        }
        setDeleteDialogOpen(false);
        setRatingToDelete(null);
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setRatingToDelete(null);
    };

    const getRatingClass = (rate: number) => {
        if (rate <= 2) return classes.starLow;
        if (rate > 2 && rate < 4) return classes.starMedium;
        return classes.starHigh;
    };

    if (loading) return <Loading />;

    return (
        <div style={{ margin: '1em' }}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate('resources.rating.fields.by')}</TableCell>
                            <TableCell>{translate('resources.rating.fields.rating')}</TableCell>
                            <TableCell>{translate('resources.rating.fields.comment')}</TableCell>
                            <TableCell>{translate('resources.rating.fields.created_at')}</TableCell>
                            <TableCell>{translate('resources.rating.actions.delete')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ratings.length > 0 ? (
                            ratings.map((e: RatingData) => (
                                <TableRow key={e._id}>
                                    <TableCell><AvatarField record={e.user} /></TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            <MuiRating
                                                name="half-rating-read"
                                                value={e.rate}
                                                precision={0.5}
                                                size="large"
                                                readOnly
                                                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                                className={getRatingClass(e.rate)}
                                            />
                                            <Typography variant="body2" style={{ marginLeft: 8 }}>
                                                ({e.rate.toFixed(1)})
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell><TextField record={e} source="comment" /></TableCell>
                                    <TableCell><DateField record={e} source="created_at" showTime={true} /></TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDeleteClick(e._id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                    Aucun Avis enregistré pour cette activité
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {translate('resources.rating.delete.title', {
                        defaultValue: 'Confirmer la suppression'
                    })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {translate('resources.rating.delete.content', {
                            defaultValue: 'Êtes-vous sûr de vouloir supprimer cet avis ? Cette action est irréversible.'
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        {translate('resources.rating.delete.cancel', {
                            defaultValue: 'Annuler'
                        })}
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                        {translate('resources.rating.delete.confirm', {
                            defaultValue: 'Confirmer'
                        })}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withStyles(styles)(Rating);