import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    TextInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { AdminMessage, FieldProps } from '../types';
import AdminMesageTargetInput from './inputs/AdminMesageTargetInput';
import AdminMessageTargetUsersInput from './inputs/AdminMesageTargetUsersInput';

const AdminMessageCreate = (props: any) => {
    return (
        <Create title={<AdminMessageTitle />} component="div" {...props}>
            <AdminMessageForm />
        </Create>
    );
};

const AdminMessageTitle: FC<FieldProps<AdminMessage>> = ({ record }) =>
    record ? <TextField /> : null;

const AdminMessageForm = (props: any) => {
    const validateMessage = [required(), minLength(1), maxLength(1000)];
    const validateHeading = [required(), minLength(1), maxLength(30)];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            mutators={{
                setUsers: (args, state, utils) => {
                    utils.changeValue(state, 'users', () => args[0]);
                },
            }}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <AdminMesageTargetInput
                                        source="target"
                                        label="resources.admin_messages.fields.target"
                                    />
                                    <AdminMessageTargetUsersInput
                                        source="users"
                                        reference="users"
                                        resource="users"
                                        label="resources.admin_messages.fields.users"
                                    />
                                    <TextInput
                                        source="heading"
                                        label="resources.admin_messages.fields.heading"
                                        validate={validateHeading}
                                        fullWidth
                                    />
                                    <TextInput
                                        source="message"
                                        label="resources.admin_messages.fields.message"
                                        validate={validateMessage}
                                        fullWidth
                                        multiline
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="admin_messages"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default AdminMessageCreate;
