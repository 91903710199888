import LocationCityIcon from '@material-ui/icons/LocationCity';

import List from './CitiesList';
import Edit from './CityEdit';
import Create from './CityCreate';

export default {
    list: List,
    edit: Edit,
    create: Create,
    icon: LocationCityIcon,
};
