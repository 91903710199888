import * as React from 'react';
import {
    Loading,
    useQueryWithStore,
    useTranslate,
    DateField,
    TextField,
} from 'react-admin';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
} from '@material-ui/core';
import UserField from '../../activities/fields/UserField';
import {
    Report as ReportIcon,
    LocationOff as LocationOffIcon,
    Schedule as ScheduleIcon,
    LocationOn as LocationOnIcon,
    Store as StoreIcon,
    Contacts as ContactsIcon,
    PhotoLibrary as PhotoLibraryIcon,
    Error as ErrorIcon,
    MoreHoriz as MoreHorizIcon,
} from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

const getIconForType = type => {
    switch (type) {
        case 'report':
            return <ReportIcon style={{ color: red[500] }} />;
        case 'lieudefinitivementFerme':
            return <LocationOffIcon style={{ color: red[500] }} />;
        case 'horairesOuverture':
            return <ScheduleIcon style={{ color: red[500] }} />;
        case 'adresseoupositionsurlacarte':
            return <LocationOnIcon style={{ color: red[500] }} />;
        case 'nomdulieu':
            return <StoreIcon style={{ color: red[500] }} />;
        case 'contacts':
            return <ContactsIcon style={{ color: red[500] }} />;
        case 'photos':
            return <PhotoLibraryIcon style={{ color: red[500] }} />;
        case 'error':
            return <ErrorIcon style={{ color: red[500] }} />;
        case 'other':
        default:
            return <MoreHorizIcon style={{ color: red[500] }} />;
    }
};

const Raport = (props: any) => {
    const { id } = props;
    const translate = useTranslate();
    const { data, loading, error } = useQueryWithStore({
        type: 'getMany',
        resource: `activities/${id}/raport`,
        payload: { $sort: { created_at: 'ASC' } },
    });

    if (loading) return <Loading />;
    if (error) {
        console.error('Error fetching ratings:', error);
        // return <Error error={error} />;
    }
    return (
        <div style={{ margin: '1em' }}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>
                                {translate('resources.raport.fields.type')}
                            </TableCell>

                            <TableCell>
                                {translate('resources.raport.fields.by')}
                            </TableCell>
                            <TableCell>
                                {translate('resources.raport.fields.raport')}
                            </TableCell>
                            <TableCell>
                                {translate(
                                    'resources.raport.fields.created_at'
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.length > 0 ? (
                            data.map(e => (
                                <TableRow key={e._id}>
                                    <TableCell>
                                        {getIconForType(e.typeReport)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {translate(
                                            `resources.raport.types.${e.typeReport}`
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        <UserField record={e.user} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField record={e} source="report" />
                                    </TableCell>
                                    <TableCell>
                                        <DateField
                                            record={e}
                                            source="created_at"
                                            showTime={true}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={6}
                                    style={{ textAlign: 'center' }}
                                >
                                    Aucun Rapport enregistre pour cette activité
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

const ProductHistory = props => <Raport id={props.id} {...props} />;
export default ProductHistory;
