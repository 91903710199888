import SlideshowIcon from '@material-ui/icons/Slideshow';

import List from './SlideList';
import Edit from './SlideEdit';
import Create from './SlideCreate';

export default {
    list: List,
    edit: Edit,
    create: Create,
    icon: SlideshowIcon,
};
