import React from 'react';
import { SelectInput, useInput, useGetList } from 'react-admin';

const ParentCategoryInput = props => {
    const {
        input: { value },
    } = useInput(props);

    const { data, isLoading } = useGetList('categories');

    const label = value ? "Parent" : "Parent: Aucun";

    const choices = React.useMemo(() => {
        const aucunOption = { id: null, name: 'Aucun' };
        if (isLoading || !data) return [aucunOption];
        
        // Check if data is an array, if not, try to extract values
        const categoryArray = Array.isArray(data) ? data : Object.values(data);
        
        return [aucunOption, ...categoryArray];
    }, [data, isLoading]);

    return (
        <SelectInput
            {...props}
            label={label}
            source="parent"
            choices={choices}
            optionText="name"
            optionValue="id"
            emptyValue={null}
            resettable
        />
    );
};

export default ParentCategoryInput;