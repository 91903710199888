import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    Filter,
    ReferenceField,
    List,
    SearchInput,
    useRecordContext,
} from 'react-admin';
import ColoredBooleanField from '../fields/common/ColoredBooleanField';
import Thumbnailfield from './fields/ThumbnailField';

const PcFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

// Custom ParentField component
const ParentField = ({ source, reference, label }) => {
    const record = useRecordContext();
    if (!record || !record[source]) {
        return <span>Aucun</span>;
    }
    return (
        <ReferenceField source={source} reference={reference} label={label}>
            <TextField source="name" />
        </ReferenceField>
    );
};

const SlideList = props => {
    return (
        <List
            {...props}
            filters={<PcFilter />}
            sort={{ field: 'title', order: 'ASC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="edit">
                <Thumbnailfield
                    source="image"
                    label="resources.categories.fields.image"
                />
                <TextField
                    source="name"
                    label="resources.categories.fields.name"
                />
                <ParentField
                    label="resources.categories.fields.parent"
                    source="parent"
                    reference="categories"
                />
                <ColoredBooleanField
                    source="isEnabled"
                    label="resources.categories.fields.is_enabled"
                />
                <DateField
                    source="created_at"
                    type="date"
                    showTime
                    label="resources.categories.fields.created_at"
                />
            </Datagrid>
        </List>
    );
};

export default SlideList;
